import TypeLanding1 from './type-landing-1'
import TypeGroup1 from './type-group-1'
import TypeGroupRec from './type-group-rec'
import TypeList4 from './type-4';
import TypeList2 from './type-2';
import TypeGroup8 from './type-group-8';
import TypeList5 from './type-5'
import TypeGroup1Replce from './type-group-replace';
import TypeFlash from './type-flash';
import Type6 from './type-6'
import TypeTop1 from './type-top-1'
import TypeBlackFlash from './type-black-flash'
import TypeGroup3 from './type-group-3'
import TypeGroup10 from './type-group-10';
import TypeGroupFestival from './type-group-festival'
import TypeGroupFestival2 from './type-group-festival-2'
import Type8 from './type-8'
import TypeGroup2 from './type-group-2'


export const Types = {
    TYPES_LIST_2: 'list-2',
    TYPES_LIST_4: 'list-4',
    TYPES_LIST_5: 'list-5',
    TYPES_LIST_6: 'list-6',
    TYPES_LIST_8: 'list-8',
    TYPES_LANDING_1: 'list-landing-1',
    TYPES_LANDING_4: 'list-landing-4',
    TYPES_GROUP_1_REPLACE: 'list-firday',
    TYPES_GROUP_2: 'list-friday-2',
    TYPES_GROUP_3: 'list-friday-3',
    TYPES_GROUP_8: 'list-friday-8',
    TYPES_GROUP_1: 'list-firday-9',
    TYPES_GROUP_10: 'list-friday-10',
    TYPES_GROUP_REC: 'list-group-1',
    TYPES_FLASH: 'list-flash',
    TYPES_LIST_TOP_1: 'list-top-1',
    TYPES_LIST_BLACK_FLASH: 'list-black-flash',
    TYPES_GROUP_10: 'list-friday-10',
    TYPES_GROUP_FESTIVAL: 'list-friday-festival',
    TYPES_GROUP_FESTIVAL_2: 'list-friday-festival-2'
}

export default type => {
    switch (type) {
        case Types.TYPES_LIST_2:
            return TypeList2;
        case Types.TYPES_LIST_4:
            return TypeList4;
        case Types.TYPES_LANDING_1:
        case Types.TYPES_LANDING_4:
            return TypeLanding1
        case Types.TYPES_GROUP_1:
            return TypeGroup1
        case Types.TYPES_GROUP_REC:
            return TypeGroupRec 
        case Types.TYPES_GROUP_8:
            return TypeGroup8;
        case Types.TYPES_LIST_5:
            return TypeList5
        case Types.TYPES_GROUP_1_REPLACE:
            return TypeGroup1Replce
        case Types.TYPES_FLASH:
            return TypeFlash
        case Types.TYPES_LIST_6:
            return Type6
        case Types.TYPES_LIST_8:
            return Type8
        case Types.TYPES_LIST_TOP_1:
            return TypeTop1
        case Types.TYPES_LIST_BLACK_FLASH:
            return TypeBlackFlash
        case Types.TYPES_GROUP_2:
            return TypeGroup2
        case Types.TYPES_GROUP_3:
            return TypeGroup3
        case Types.TYPES_GROUP_10:
            return TypeGroup10
        case Types.TYPES_GROUP_FESTIVAL:
            return TypeGroupFestival   
        case Types.TYPES_GROUP_FESTIVAL_2:
            return TypeGroupFestival2     
        default:
            return
    }
}